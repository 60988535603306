@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Josefin+Sans:wght@100&family=Lato&family=Montserrat:wght@500&display=swap');
$font-lato: 'Lato', cursive;
$font-dosis: 'Dosis', sans-serif;
$font-josefin: 'Josefin Sans', serif;
$font-montserrat: 'Montserrat', serif;
$color-QueenBlue: #28445a;
$color-ViridianGreen: #406787;
$color-MediumTurquoise: #64D9D2;

//FF9F1C
$color-Opal: #FF7600;
$color-UnitedNationBlue: #2EC4B6;
$color-Cultured: #CBF3F0;
$black: #333;
$white: #fff ;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$minHeight:  95vh;
$boxShadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    margin: 1rem auto;
    max-width: 1200px;
    font-family: $font-lato;
    color: $color-QueenBlue;
    background: #F7F7F7;
}

button{
    cursor: pointer;
}

li{
    list-style-type: none;
}

a{
    text-decoration: none;
    color: $color-QueenBlue;
    cursor: pointer;
}

a:visited{
    color: $color-QueenBlue;
}

.home, .skills, .portfolio, .contact{
    display: flex;

    @media screen and (max-width: 850px){
        display: block !important;
    }
}

.homeContent, .skillsContent, .portfolioContent, .contactContent{
    margin-left: .4rem;

    @media screen and (max-width: 850px){
        margin-left: 0 !important;
    }
} 



