.sideBar {
    background: $white;
    font-family: $font-lato;
    height: 100%;
    min-height: $minHeight;
    width: 264px;
    border-radius: $borderRadius;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 250px 1fr 108px;
    grid-template-areas: "1" "2" "3";
    box-shadow : 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);
 

    .id {
        background: $color-UnitedNationBlue;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        grid-area: 1;
        text-align: center;
        user-select: none;
        position: relative;
        .idContent {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: inline-block;
            margin-bottom: 1rem;
            h3 {
                font-size: 1.3rem;
                text-transform: uppercase;
                letter-spacing: .2rem;
                margin-bottom: 1.5rem;
                color: $white;
            }
            .profileImage{
                border: 10px solid $color-Cultured;
                display: block;
                align-items: flex-end;
                margin: auto;
                border-radius: 50%;
                height: 150px;
                width: 150px;
                background: url(navigation-media/milanRougemont.jpg) no-repeat center top/cover;
    
            }
           
        }
    }
    .navigation {
        grid-area: 2;
        padding: 2rem 1rem;
    
        ul {
          height: 100%;
          display: grid;
        
          li {
            padding: 1rem 0 1rem .4rem;
            font-size: 1.3rem;
            position: relative;
    
            &:hover i {
                transform: translate(3px, 3px);
              }
            
    
            a {
            color: $color-UnitedNationBlue;
            padding-bottom: 0.5rem;
            }
            .navActive {
              color: $color-Opal;
            }
    
            i {
              position: absolute;
              font-size: 0.95rem;
              transform: translateY(3px);
              transition: .2s ease;
            }
            span {
              margin-left: 2.7rem;
            }
          }
        }
      }
      .socialNetwork{
          grid-area: 3;
          ul{
              display: flex;
              width: 264px;
              padding: 0 14px;
              justify-content: space-between;

              li{
                  font-size: 1.5rem;
                  background-color: $color-UnitedNationBlue;
                  color: $white;
                  border-radius: 50%;
                  padding: .5rem .8rem;
                  transition: .3s;
                  cursor: pointer;

                  a:visited{
                      color: $white;
                  }
                  &:nth-child(1){
                      padding: .5rem .75rem;
                  }
                  &:nth-child(3){
                      padding: .5rem .75rem;
                  }
                  &:hover{
                      a{
                      color: $black;}
                      background: $color-Opal;
                  }
              }
          }
          .copyright{
              margin-top: 2rem;
              text-align: center;
              letter-spacing: .1rem;
              font-size: .7rem;

              p {
                  color: $color-UnitedNationBlue;
                  
                  &:hover{
                      color:$color-Opal
                  }
              }
          }
      }
}

@media screen and (max-width: 850px){
    .sideBar{
        display: flex;
        min-height: 50px;
        margin-bottom: .2rem;
        width: 100%;

        .id , .socialNetwork{
            display: none;
        }

        .navigation {
            width: 100%;
            padding: .1rem 1rem;

            ul{
                display: flex;
                justify-content: space-between;

                span{
                    margin-left: 1.5rem !important;
                }
            }
        }
    }
}

@media  screen and (max-width: 580px) {
    .sideBar{
        .navigation ul{
            justify-content: space-around;
            li{
                padding: 1rem 0 o !important;
            }

            i{
                font-size: 1.4rem !important;
                transform: translateY(-3px) !important;
            }

            span{
                display: none;
            }
        }
    }
}

@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 50%;
	}
}