.skills {
    .skillsContent {
      min-height: $minHeight;
      width: 100%;
      position: relative;
      overflow: hidden;
      display: grid;
      grid-template-columns: 38% 1fr 1fr;
      grid-template-rows: 70% 30%;
      grid-template-areas: 
        "L E E"
        "O O H";
  
      .languagesFrameworks, .experiences, .otherSkills, .hobbies {
        background: $white;
        border-radius: $borderRadius;
        padding: .8rem;
      }
      h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        font-family: $font-dosis;
        color: $black;
        text-transform: uppercase;
      }
  
      .languagesFrameworks {
        grid-area: L;
        margin-bottom: .5rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
          "L"
          "F";
  
        .languageDisplay {
          grid-area: L;
        }
        .frameworkDisplay {
          grid-area: F;
        }
        h3 {
          margin-bottom: 0.3rem;
        }
  
        .years {
          font-size: .65rem;
          display: flex;
          justify-content: space-between;
          color: $black;
            
          span {
            width: 36%;
            
            &:nth-child(2){
              text-align: center; 
              width: 20%;
            }
            &:nth-child(3),&:nth-child(4){
              text-align: right;
              width: 44%;
            }
          
            &:nth-child(2)::after,
            &:nth-child(3)::after
            {
              content:'';
              background: rgba(138, 132, 132, 0.507);
              position: absolute;
              height: 182px;
              width: 1px;
              transform: translate(9px, 19px);
            }
          }
        }
        .languagesList {
          padding-top: .5rem;
  
          li {
            color: $color-QueenBlue;
          }
          .progressBar {
            height: .4rem;
            background: transparent;
            border-radius: 50px;
            margin-top: 0.2rem;
            position: relative;
  
            &:after {
              content: '';
              position: absolute;
              background: $color-Opal;
              border-radius: 50px;
              height: .4rem;
              width: 0;
              animation: line 2s ease-out forwards;
  
              @keyframes line {
                to {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .experiences {
        grid-area: E;
        margin: 0 0 .5rem .5rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 10% 1fr 1fr 1fr;
        grid-template-areas: 
          "1"
          "2"
          "3"
          "4";
        
        h3 {
          grid-area: 1;
        }
        h4 {
          font-size: 1.1rem;
          color: $black;
        
        }
        h5 {
          padding: 0.5rem 0 0.5rem 1rem;
        }
        p {
          text-align: justify;
          padding: 0 1rem;
        }
        .exp1 {
          grid-area: 2;
        }
        .exp2 {
          grid-area: 3;
        }
        .exp3{
            grid-area: 4;
        }
      }
      .otherSkills {
        grid-area: O;
        margin-right: 0.5rem;
  
        .list {
          display: flex;
          width: 80%;
          height: 100%;
          margin: 0 auto;
          justify-content: space-between;
  
          ul {
            height: 80%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            li{
                margin-right: 0.5rem;
                i {
                    margin-right: 0.4rem;
                    color: $color-Opal;
                }
            }   
          }
        }
      }
      .hobbies {
        grid-area: H;
        padding-left: 1.5rem;
  
        ul {
          height: 80%;
          display: grid;
  
          li {
            i {
              position: absolute;
              color: $color-Opal;
            }
            span {
              position: absolute;
              transform: translateX(2.2rem);
              line-height: 1;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 950px){
    .skillsContent {
      h3 {
        font-size: 1.2rem !important;
      }
      .languagesList {
        padding-top: .2rem !important;
      }
      p {
        font-size: .8rem;
      }
      .otherSkills, .hobbies {
        font-size: .8rem;
      }
    }
  }
  @media screen and (max-width: 850px){
    .skillsContent {
      h4 {
        font-size: .9rem !important;
      }
      h5 {
        font-size: .6rem !important;
        padding: .2rem 1rem !important;
      }
    }
  }
  @media screen and (max-width: 500px){
    .skillsContent {
      display: grid !important;
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr 1fr 180px 180px !important;
      
      .languages {
        grid-area: 1 !important;
      }
      .experiences {
        grid-area: 2 !important;
        margin-left: 0 !important;
        .exp1{
          margin-bottom: 1rem;
        }

      }
      .otherSkills {
        grid-area: 3 !important;
        margin-right: 0 !important;
      }
      .hobbies {
        grid-area: 4 !important;
        margin-top: .5rem !important;
      }
      .languagesFrameworks .years span:nth-child(2):before, .languagesFrameworks .years span:nth-child(3):before {
        height: 155px  !important;
    }
    }

    @media screen and (max-width: 280px) {
      .skillsContent .otherSkills{
        width: auto;
      }
    }
  }