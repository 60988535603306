.notFound {
    @include verticalCenter;
    height: 95vh;
    max-width: 1200px;
    background: $white;
    border-radius: $borderRadius;
    text-align: center;
    box-shadow: $boxShadow;
  
    h3 {
      margin-bottom: 3rem;
      font-size: 2rem;
      color: $black;
    }
    a {
      padding: 1rem 2rem;
      border-radius: $borderRadius;
      background: $color-UnitedNationBlue;
      color: $white;
      transition: .2s;
  
      &:hover {
        background: $color-Opal;
        color: $white;
      }
    }
  }