.contactContent {
    box-shadow: $boxShadow;
    background: $white;
    border-radius: $borderRadius;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 45% 35%;
    grid-template-areas: 
      "1"
      "2"
      "3";
  
    .header {
      grid-area: 1;
      width: 100%;
      position: relative;
      background: $color-Cultured;
      height: 340px;
  
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: url(../../media/map.jpg) no-repeat center top/cover;
      }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 30px;
        background: url(../../media/shape.svg) no-repeat center /cover;
  
      }
    }
    .contactBox {
      grid-area: 2;
      background: $color-UnitedNationBlue;
      margin: 0 auto;
      padding: 1rem 3rem;
      z-index: 2;
      border-radius: $borderRadius;
      box-shadow: 0 3px 10px #333;
      width: 50%;
      min-width: 440px;
      min-height: 200px;
  
      h1 {
        text-align: center;
        margin-top: 0.3rem;
        position: relative;
        color: $white;
  
        &:after {
          content: '';
          height: 1px;
          width: 50%;
          background: $white;
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      ul {
        height: 72%;
        width: inherit;
        display: grid;
        margin-top: 3.5rem;
  
        li {
          position: relative;
        }
  
        i {
          font-size: 1.4rem;
          position: absolute;
          transform: translateY(2px);
          color: $white;
        }
        span {
          font-size: 1.4rem;
          position: absolute;
          transform: translateX(52px);
          background: transparent;
          border: none;
          outline: none;
          width: 350px;
          transition: .2s ease;
        }
        .clickInput:hover {
          transform: translateX(56px);
          cursor: pointer;
        }
      }
    }
    .socialNetwork {
      grid-area: 3;
      @include verticalCenter;
  
      ul {
        display: flex;
        text-align: center;
        width: 100%;
        justify-content: space-around;
  
        a {
          width: 20%;
          font-size: 1.2rem;
          background: $color-UnitedNationBlue;
          border-radius: $borderRadius;
          display: inline-block;
          padding: 0.7rem 0;
          box-shadow: $shadow;
          transform: scaleY(0);
          transform-origin: center;
          transition: .2s ease;
          transition: scale .1s;
          color: $white;
          &:hover {
            background: $color-Opal;
            color: $black;
            i{
              color: $black ;
            }
          }
  
          i {
            color: $white;
            margin-top: .3rem;
          }
        }
        //ANIMATION
        @mixin networkAnim($timeout) {
          animation: pop 1s forwards;
          animation-delay: $timeout;
  
        }
        @keyframes pop {
          to {
            transform: scaleY(1);
          }
        }
        a:nth-child(1) {
          @include networkAnim(.4s);
        }
        a:nth-child(2) {
          @include networkAnim(.8s);
        }
        a:nth-child(3) {
          @include networkAnim(1.2s);
        }
        a:nth-child(4) {
          @include networkAnim(1.6s);
        }
      }
    }
  }
  @media screen and (max-width: 500px){
    .contactContent {
      .contactBox {
        min-width: 100% !important;
      }
      span {
        font-size: .9rem !important;
      }
      h4 {
        font-size: .9rem;
      }
    }
  }